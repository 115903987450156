import React from 'react'

import logo from '../assets/images/logo.png';

const Header = (props) => (<header id="header" className="alt">
  <span className="logo"><img src={logo} alt=""/></span>
  <h1>Cabinet de Podologie du Sport de Lanta</h1>
  <div className="subtitle-container">
    <span className="subtitle-item">Pédicure Podologue</span>
    <span className="subtitle-separator">&nbsp;·&nbsp;</span>
    <span className="subtitle-item">Podologie de sport</span>
    <span className="subtitle-separator">&nbsp;·&nbsp;</span>
    <span className="subtitle-item">Réflexologie plantaire</span>
  </div>
</header>)

export default Header
