import {Link} from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import {Waypoint} from 'react-waypoint'
import pic01 from '../assets/images/marianne.png'
import pic02 from '../assets/images/pauline.png'
import doctolib from '../assets/images/doctolib.png'
import Header from '../components/Header'
import Layout from '../components/layout'
import Nav from '../components/Nav'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({stickyNav: false}))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({stickyNav: true}))
  }

  render() {
    return (<Layout>
      <Helmet defer={false} htmlAttributes={{
          lang: 'fr'
        }}>
        <title>Cabinet de Podologie du Sport de Lanta</title>
        <meta name="description" content="Pédicure podologue situé à Lanta, proche de Toulouse, au sein d'un cabinet paramédical regroupant des kinésithérapeutes du sport ainsi que des médecins pour une prise en charge pluridisciplinaire du patient."/>
      </Helmet>

      <Header/>

      <Waypoint onEnter={this._handleWaypointEnter} onLeave={this._handleWaypointLeave}></Waypoint>
      <Nav sticky={this.state.stickyNav}/>

      <div id="main">

        <section id="contact" className="main special">
          <ul className="features">
            <li>
              <h2>Marianne ARGOUD-PUY</h2>
              <p className="doctolib">
                <Link to="https://www.doctolib.fr/pedicure-podologue/lanta/marianne-argoud-puy" className="button special doctolib">
                  Prendre RDV sur <img src={doctolib} alt=""/>
                </Link>
              </p>
            </li>
            <li>
              <h2>Pauline FONTORBES</h2>
              <p className="doctolib">
                <Link to="https://www.doctolib.fr/pedicure-podologue/balma/pauline-fontorbes?pid=practice-128657" className="button special doctolib">
                  Prendre RDV sur <img src={doctolib} alt=""/>
                </Link>
              </p>
            </li>
          </ul>
          <footer className="major">
            <p><b>06 75 26 28 60</b></p>
            <p>Consultation uniquement sur rendez-vous, au Centre médical de Lanta ou à domicile.</p>
          </footer>
        </section>

        <section id="equipe" className="main special">
          <header className="major">
            <h2>L'équipe</h2>
          </header>
          <p>Mme Marianne Argoud-Puy et Mme Pauline Fontorbes travaillent au sein d'un cabinet médical et paramédical pour une prise en charge pluridisciplinaire des patients.</p>
          <div className="spotlight">
            <ul className="features">
              <li>
                <span className="image">
                  <img src={pic01} alt=""/>
                </span>
                <h3><b>Marianne ARGOUD-PUY</b></h3>
                <p>
                  Pédicure Podologue D.E.<br/>Podologue du sport D.U.<br/>Préférences motrices Volodalen®<br/>Réflexes archaïques<br/>Réflexologie plantaire
                </p>
              </li>
              <li>
                <span className="image">
                  <img src={pic02} alt=""/>
                </span>
                <h3><b>Pauline FONTORBES</b></h3>
                <p>
                  Pédicure Podologue D.E.
                </p>
              </li>
            </ul>
          </div>
        </section>

        <section id="horaires-tarifs" className="main special">
          <div className="spotlight">
            <ul className="features">
              <li>
                <header className="major">
                  <h2>Horaires</h2>
                </header>
                <span className="icon major style1 fa-calendar"></span>
                <p className="horaires">Lundi au Vendredi<br/>9h - 12h<br/>13h - 19h</p>
              </li>
              <li>
                <header className="major">
                  <h2>Tarifs</h2>
                </header>
                <span className="icon major style1 fa-eur"></span>
                <table className="price">
                  <tr>
                    <td>Soin de pédicurie</td>
                    <td>40 €</td>
                  </tr>
                  <tr>
                    <td>Bilan podologique + semelles orthopédiques</td>
                    <td>165 €</td>
                  </tr>
                  <tr>
                    <td>Réflexologie plantaire</td>
                    <td>55 €</td>
                  </tr>
                </table>
              </li>
            </ul>
          </div>
        </section>

        <section id="acces" className="main special">
          <header className="major">
            <h2>Accès</h2>
          </header>
          <i class="icon fa-wheelchair"></i>&nbsp;accessible en fauteuil&nbsp;&bull;&nbsp;<i class="icon fa-car"></i>&nbsp;parking gratuit
          <p id="gmaps">
            <iframe title="Plan Google Maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d46250.756559331254!2d1.516702586707044!3d43.57171134176236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12ae91946e028105%3A0xc482e28aac7bab8f!2sArgoud-Puy%20Marianne%20Podologue%20du%20sport%20LANTA!5e0!3m2!1sfr!2sfr!4v1621176121024!5m2!1sfr!2sfr" loading="lazy"></iframe>
          </p>
        </section>
      </div>
    </Layout>)
  }
}

export default Index
